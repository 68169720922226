import axios from 'axios';
import config from './Config';
import store from '../store/index';

export default class Apicall {
  return = {
    status: 'OK',
    data: null,
    msg: null,
    complete: null,
  };

  header = {};

  getHeaders(authToken: string|null = null) {
    let thetoken = !authToken ? store.state.token : authToken;
    if (!thetoken) {
      thetoken = !authToken ? String(window.localStorage.getItem('token')) : authToken;
    }
    this.header = {
      headers: {
        'Accept-Language': store.state.currentlang,
        Accept: 'application/json',
        Authorization: `Bearer ${thetoken}`,
      },
    };
    return this.header;
  }

  async call(method: string, path: string, postdata?: any, authToken: string|null = null) {
    const self = this;
    const domain = config.basedomain;
    if (method === 'GET') {
      return axios.get(`${domain}${path}`, self.getHeaders(authToken)).then((response: any) => {
        self.return.data = response.data.data;
        self.return.msg = response.data.msg;
        self.return.status = response.data.status;
        return self.return;
      }).catch((response: any) => {
        self.return.status = 'ERROR';
        self.return.msg = response.response.msg;
        if (response.response.data.msg) {
          self.return.msg = response.response.data.msg;
        }
        return self.return;
      });
    }

    return axios.post(`${domain}${path}`, postdata, self.getHeaders(authToken)).then((response: any) => {
      self.return.data = response.data.data;
      self.return.msg = response.data.msg;
      self.return.status = response.data.status;
      self.return.complete = response.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.msg;
      if (response.response.data.msg) {
        self.return.msg = response.response.data.msg;
      }
      return self.return;
    });
  }
}
